import { useEffect, useState } from 'react';
import style from './index.module.scss';
import { ButtonLink, IntrinsicProps, createComponent } from 'react-commons';

interface LoginButtonProps extends IntrinsicProps {
  loginOnClick?: boolean
}

export default createComponent<LoginButtonProps>('LoginButton', { style }, function LoginButton ({ className }, props) {
  const [ returnTo, setReturnTo ] = useState<string>('/');
  useEffect(() => {
    setReturnTo(window.location.pathname);
  }, []);

  return (
    <div 
      className={className}
    >
      <ButtonLink secondary href={`/account/login?returnTo=${returnTo}`}>Login</ButtonLink>
      <ButtonLink secondary href={`/account/register?returnTo=${returnTo}`}>Signup</ButtonLink>
    </div>
  );
});
